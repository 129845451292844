<template>
    <div class="product_variable_size">
        <h4 class="tetx_m mb5">{{typeText(type)}}:</h4>
        <span 
            v-for="(option, index) in options"
            :key="index"
            @click="changeCurrent(option.id)"
            :class="[{
                disabled: option.disabled, 
                selected: current==option.id,
                product_variable_size_item: option.type == 2,
                product_variable_color_item: option.type == 1
            }, option.text]"
        >{{option.text}}</span>
    </div>
</template>

<script>
export default {
    name: 'VityOptions',
    props:{
        options: {
            type: Array,
        },
        type: {
            type: String || Number,
        }
    },
    methods: {
        typeText: function(type){
            switch(type){
                case '1':
                    return 'Color';
                case '2':
                    return 'Talla';
            }
        },
        changeCurrent: function(opcion){
            this.current = opcion;
            this.$emit('input', opcion);
        }
    },
    data: function(){
        return{
            current: null
        }
    }
}
</script>